.demo-b2b-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.demo-b2b-content {
  max-width: 800px;
  width: 100%;
  margin-bottom: 2rem;
}

h1 {
  font-weight: 800;
  margin-bottom: 1.5rem;
}

h3 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

h3 span {
  font-weight: 500;
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 2px solid #000;
  border-radius: 1.5625rem;
  outline: none;
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.search-input::placeholder {
  animation: fadeInOut 3s infinite;
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 1.5625rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #333;
}

.personality-container {
  margin-top: 1rem;
}

.personality-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.personality-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}


.personality-button.active,
.personality-button:hover {
  opacity: 1;
}

.personality-button img {
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.personality-button span {
  font-size: 0.9rem;
  color: #9E9D99;
}

.demos-comparison {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.results-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.results-column {
  flex: 1;
  border: 2.5px solid black;
  border-radius: 1.5625rem;
  overflow: hidden;
}

.search-results {
  height: 100%;
  overflow-y: auto;
}

.search-results h3 {
  margin: 1rem;
  font-weight: 600;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 1rem;
}

.result-item {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.result-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.styled-logo {
  width: 109px;
  height: 22.47px;
  margin: 1rem;
}

@media (min-width: 768px) {
  .search-container {
    flex-direction: row;
  }

  .search-button {
    width: auto;
  }

  .results-container {
    flex-direction: row;
  }
}

.search-suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.suggestion-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 1.5625rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  color:black;
  transition: all 0.3s ease;
}

.suggestion-button:hover {
  background-color: #000;
  color: #fff;
}

.store-select {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 2px solid #000;
  border-radius: 1.5625rem;
  outline: none;
  transition: all 0.3s ease;
  background-color: white;
  cursor: pointer;
}

.store-select:focus {
  border-color: var(--primary-color, #000);
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  padding:2rem;
}

.product-item {
  position: relative;
  background-color:white;
  aspect-ratio: 1 / 1;
  border: 2px solid #CECCC5;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.explanation-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: help;
}
