.embed-designer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Inter', sans-serif;
}

.embed-designer h1 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.designer-container {
  display: flex;
  gap: 20px;
}

.customization-panel, .preview-panel {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align:left;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.customization-panel label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.customization-panel input,
.customization-panel select {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.customization-panel input[type="color"] {
  height: 40px;
  padding: 0;
}

.preview-panel h2,
.embed-code h2 {
  margin-bottom: 1rem;
  color: #333;
}

.embed-code textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9rem;
  resize: vertical;
}

.embed-code button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.embed-code button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .designer-container {
    flex-direction: column;
  }
}

.preview-panel {
  flex: 0 0 800px; /* Fixed width, adjust as needed */
}

.widget-preview {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}