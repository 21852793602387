.home-container {
  position: relative;
  padding-top: 18em;
}

.animation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.main-content {
  position: relative;
  z-index: 2;
  padding-top: 40px;
}

.glass-input {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
}


.action-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.video-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  width: 100%;
  max-width: 560px;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.windowpane {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.6);
  padding: 30px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

.storefront {
  text-align: center;
  padding: 20px;
}

@media (max-width: 767px) {
  .main-content {
    flex-direction: column-reverse;
  }
  
  .text-content {
    padding: 30px;
    margin-top: 20px;
  }
  
  .video-container {
    max-width: 100%;
  }
}

.storefront {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.storefront-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 180px;
}

.storefront-button:hover {
  background-color: #333;
  color: #fff;
}

.waitlist-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.waitlist-input {
  flex: 1;
  min-width: 0;
}


.waitlist-button:hover {
  background-color: #555;
}

.success-message {
  color: #333;
  font-weight: bold;
}
