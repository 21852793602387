.contain-image {
  object-fit: contain;
  height: 200px; /* Adjust this value as needed */
  width: 100%;
  padding: 1rem;
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.card-link:hover {
  text-decoration: none;
  color: inherit;
}

.card-link .card {
  transition: transform 0.2s, box-shadow 0.2s;
}

.card-link:hover .card {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}