/* Looper styles */
@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100vw;
  overflow: hidden;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.looper__innerList {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim linear infinite;   
}

.looper__listInstance {
  display: flex;
  width: max-content;
  animation: none;
}

.contentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 16px; 
  text-align: center;
  /* border: 1px solid red; */
  margin-left: -110px;
}
