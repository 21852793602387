.demo-b2c-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  width: 100%;
}

.demo-b2c-content {
  width: 100%;
  margin-bottom: 2rem;
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.search-input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 2px solid #000;
  border-radius: 1.5625rem;
  outline: none;
  transition: all 0.3s ease;
}

.search-input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.search-input::placeholder {
  animation: fadeInOut 3s infinite;
}

.store-select {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 2px solid #000;
  border-radius: 1.5625rem;
  outline: none;
  transition: all 0.3s ease;
  background-color: white;
  cursor: pointer;
}

.store-select:focus {
  border-color: var(--primary-color, #000);
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: var(--primary-color, #000);
  color: #fff;
  border: none;
  border-radius: 1.5625rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--secondary-color, #333);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .search-container {
    flex-direction: row;
  }

  .search-button {
    width: auto;
  }
}

.search-suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.suggestion-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 1.5625rem;
  color:black;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggestion-button:hover {
  background-color: var(--primary-color, #000);
  color: #fff;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.product-item {
  position: relative;
  background-color:white;
  aspect-ratio: 1 / 1;
  border: 2px solid #CECCC5;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.explanation-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: help;
}
