.events-tab {
  position: fixed;
  right: -200px;
  top: 50%;
  transform: translateY(-50%);
  width: 250px;
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
}

.events-tab.open {
  right: 0;
}

.events-content {
  text-align: center;
}

.events-content h3 {
  margin-bottom: 10px;
  font-weight: bold;
}

.events-content p {
  margin-bottom: 15px;
}

.events-content .btn-light {
  background-color: white;
  color: #FF1493;
  border: none;
  font-weight: bold;
}