.survey-content {
  height: 85vh;
  /* background-color: green; */
  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  overflow-y:scroll;
}

.survey-title{
    font-weight: 500;
    font-size: 1.5rem;
    /* background-color: red; */
}

.question{
    font-weight: 900;
    font-size: 3rem;
    /* background-color: red; */
    width: 50%;
    margin: 0 auto;
    color: #000;
    margin-bottom: 20px;
}

.custom-button {
  background-color: transparent;
  border: none;
  color: #000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #f0f0f0;
}

.custom-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.survey-option {
  transition: opacity 0.3s ease;
  opacity: 1;
}

.survey-option:not(.selected) {
  opacity: 0.5;
}

.d-flex.flex-wrap.justify-content-center {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .survey-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .question {
    font-size: 2rem;
    width: 90%;
  }

  .survey-option {
    flex-basis: calc(100% * (1/2) - 10px - 1px);
  }
}

@media (max-width: 480px) {
  .question {
    font-size: 1.5rem;
  }

  .survey-option {
    flex-basis: 100%;
  }
}

.survey-complete h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.survey-complete h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.survey-complete .card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.survey-complete .card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.survey-complete .card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.survey-complete .card-text {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .survey-complete h2 {
    font-size: 2rem;
  }

  .survey-complete h3 {
    font-size: 1.5rem;
  }

  .survey-complete .col-md-6 {
    padding: 0 10px;
  }
}

.error-message {
  font-size: 1rem;
  color: #dc3545;
  margin-bottom: 1rem;
}

.PhoneInput {
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputInput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.PhoneInputCountry {
  margin-right: 0.5rem;
}

/* Add these styles at the end of your existing CSS file */

/* Styles for the auth question container */
.auth-question-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #F1EFE7;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Styles for the auth question title */
.auth-question-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #000;
}

/* Styles for the auth question text */
.auth-question-text {
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #333;
}

/* Styles for the auth buttons container */
.auth-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Styles for the auth buttons */
.auth-button {
  padding: 0.75rem 1.5rem;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.auth-button-signup {
  background-color: #FE3C00;
  color: white;
}

.auth-button-signup:hover {
  background-color: #FF6637;
}

.auth-button-login {
  background-color: transparent;
  border: 2px solid #CECCC5;
  color: #000;
}

.auth-button-login:hover {
  background-color: #CECCC5;
}

/* Styles for the logged-in message */
.auth-logged-in-message {
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #333;
}

/* Styles for the continue button when logged in */
.auth-continue-button {
  background-color: #FE3C00;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.auth-continue-button:hover {
  background-color: #FF6637;
}