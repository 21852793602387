*{
    font-family: 'Inter', sans-serif !important;
    /* color: red !important; */
}
.dropdown-toggle::after {
    display:none !important;
}

.nav-pills .nav-link {
  background-color: #343a40;
  color: #ffffff;
  border: 2px solid #343a40;
  transition: all 0.3s ease;
}

.nav-pills .nav-link:hover {
  background-color: #23272b;
  border-color: #23272b;
}

.nav-pills .nav-link.active {
  background-color: #ffffff !important;
  color: #343a40 !important;
  border: 2px solid #343a40;
  font-weight: bold;
}

.events-link {
  position: relative;
  font-weight: bold;
  color: #FF1493 !important;
}

.sparkle {
  display: inline-block;
  animation: sparkle 1.5s infinite alternate;
}

@keyframes sparkle {
  0% { opacity: 0.5; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1.2); }
}