* {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #F1EFE7;
  overflow: auto;
}

.content {
  flex: 1 0 auto;
  overflow-x: hidden;
}

#global-chat-widget-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.site-footer {
  flex-shrink: 0;
  position: relative !important;
}
