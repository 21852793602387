.card {
  transition: transform 0.2s;
}


.card-img-top {
  height: 200px;
  object-fit: cover;
}

.events-container {
  min-height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
}

.event-card {
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.2s;
}

.event-card:hover {
  transform: scale(1.05);
}

.event-card .card-img-top {
  height: 200px;
  object-fit: cover;
}

.join-event-btn {
  background-color: #FF1493;
  border-color: #FF1493;
}

.join-event-btn:hover {
  background-color: #FF69B4;
  border-color: #FF69B4;
}

.join-waitlist-btn {
  border-radius: 25px;
  padding: 0.5rem 2rem;
}

.logo {
  font-family: 'Brush Script MT', 'Brush Script Std', 'Lucida Calligraphy', 'Lucida Handwriting', 'Apple Chancery', cursive;
  font-size: 4rem;
  color: #DAA520; /* Golden color */
  text-align: center;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.profile-images {
  justify-content: center;
  margin-bottom: 1rem;
}

.profile-image {
  width: 40px;
  height: 40px;
  margin: 0 -5px;
  border: 2px solid #FFF0F5;
}

.btn-outline-dark {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
}

.main-card {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.main-card .card-img-top {
  height: 300px;
  object-fit: cover;
  filter: brightness(0.7); /* Darken the image slightly to make text more visible */
}

.main-card .card-body {
  padding: 2rem;
}

.join-invite-btn {
  background-color: black;
  border-color: #B19CD9;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  font-weight: bold;
}

.join-invite-btn:hover {
  background-color: #9370DB;
  border-color: #9370DB;
}

.good-looks-text {
  font-family: 'Brush Script MT', cursive;
  font-size: 3rem;
  color: #DAA520; /* Golden color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  text-align: center;
}
.circle-logo-container {
  width: 120px; /* Reduced from 150px */
  height: 120px; /* Reduced from 150px */
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  padding-top: 15px; /* Adjusted from 20px */
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.circle-logo-container .logo {
  width: 80%;
  height: auto;
  object-fit: contain;
  filter: brightness(0) invert(1); /* This makes the image white */
}

.waitlist-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waitlist-input, .waitlist-button {
  width: 100%;
  max-width: 300px;
  height: 40px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.waitlist-button {
  background-color: #000;
  color: #fff;
  font-weight: bold;
}

@media (min-width: 576px) {
  .waitlist-form {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .waitlist-input, .waitlist-button {
    max-width: 150px;
    margin-right: 10px;
  }

  .waitlist-button {
    margin-right: 0;
  }
}

.good-looks-text {
  font-family: 'Brush Script MT', cursive;
  font-size: 3rem;
  color: #DAA520; /* Golden color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  text-align: center;
}

.events-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px 0;
  -webkit-overflow-scrolling: touch;
}

.event-card {
  flex: 0 0 auto;
  width: 300px;
}

.event-card img {
  height: 200px;
  object-fit: cover;
}

.past-event {
  opacity: 0.8;
}

.event-image-container {
  position: relative;
}

.event-image-container img {
  height: 200px;
  object-fit: cover;
}

.past-event-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  z-index: 1;
}

.past-event-banner {
  position: absolute;
  top: 20px;
  right: -35px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 30px;
  transform: rotate(45deg);
  font-size: 14px;
}

.past-event-indicator {
  font-size: 0.8rem;
  background-color: lightgray;
  color: black;
  padding: 0.25rem 0.5rem;
  border-radius: 15px;
  margin-left: 10px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tumblr-container {
  max-width: 800px;
  margin: 0 auto;
}

.tumblr-post {
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tumblr-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.tumblr-post .card-body {
  padding: 2rem;
}

.tumblr-post .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.tumblr-post img {
  border-radius: 8px;
  margin-bottom: 1rem;
}

.tumblr-post a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease;
}

.tumblr-post a:hover {
  color: #007bff;
}

.tumblr-post .card-footer {
  background-color: #f8f9fa;
  border-top: none;
  font-size: 0.9rem;
}

.tumblr-post blockquote {
  font-size: 1.25rem;
  border-left: 5px solid #007bff;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.tumblr-post .blockquote-footer {
  font-size: 1rem;
}

.tumblr-post .badge {
  font-size: 0.8rem;
  font-weight: normal;
}

.tumblr-post .card-footer {
  font-size: 0.9rem;
}

.tumblr-post iframe {
  max-width: 100%;
}

.tumblr-post .carousel {
  margin-bottom: 1rem;
}

.tumblr-post .carousel-inner {
  border-radius: 8px;
  overflow: hidden;
}

.tumblr-post .carousel-item {
  background-color: #000;
}

.tumblr-post .carousel-image {
  max-height: 500px;
  object-fit: contain;
  width: 100%;
}

.tumblr-post .carousel-control-prev,
.tumblr-post .carousel-control-next {
  background-color: rgba(0, 0, 0, 0.5);
  width: 10%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tumblr-post .carousel:hover .carousel-control-prev,
.tumblr-post .carousel:hover .carousel-control-next {
  opacity: 1;
}

.tumblr-post .carousel-indicators {
  bottom: 0;
  margin-bottom: 0;
}

.tumblr-post .carousel-indicators button {
  background-color: #fff;
  opacity: 0.5;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
}

.tumblr-post .carousel-indicators button.active {
  opacity: 1;
}

.tumblr-post .image-gallery {
  margin-bottom: 1rem;
}

.tumblr-post .image-gallery img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.tumblr-post .image-gallery img:hover {
  transform: scale(1.05);
}