.toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px; /* Adjust width */
  height: 60px; /* Adjust height */
  border-radius: 30px; /* Make it more rounded */
  background-color: var(--primary-color, #4a4a4a); /* Fallback color */
  color: #fff; /* White text */
  border: none;
  font-size: 17px; /* Increase font size */
  font-weight: bold; /* Make text bold */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  font-family: var(--font-family, Arial, sans-serif);
}

.toggle-button:hover {
  background-color: #333; /* Darker on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.toggle-button img {
  margin-right: 10px; /* Space between icon and text */
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1001;
}

.drawer {
  width: 50vw; /* Default width for larger screens */
  height: 100%;
  
  border-top-left-radius: var(--border-radius, 20px);
  border-bottom-left-radius: var(--border-radius, 20px);
  background-color: var(--background-color, #ffffff);
  color: var(--text-color, #333333);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  font-family: var(--font-family, Arial, sans-serif);
}

@media (max-width: 700px) { /* Adjust the max-width as needed */
  .drawer {
    width: 100%; /* Full width for small screens */
    border-radius: 0; /* Remove border radius */
  }
}
.close-button {
  align-self: flex-end;
  background: none;
  border: none;
  color:black;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;
}

.close-button-init {
  position: absolute; /* Position it absolutely */
  top: 15px; /* Adjust as needed */
  right: 15px; /* Adjust as needed */
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: var(--primary-color, #000);
  color: #fff;
  border: none;
  border-radius: 1.5625rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  height: 40px; /* Set a fixed height */
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.chat-header h2 {
  flex-grow: 1;
  margin: 0;
  font-size: 18px;
}

.start-again {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px; /* Adjust font size */
  padding: 10px 15px; /* Add padding for height */
  border-radius: 5px; /* Add border radius for a better look */
  transition: background-color 0.3s; /* Add transition for hover effect */
}

.start-again:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light background on hover */
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

.user, .bot {
  max-width: 80%;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 18px;
  animation: fadeIn 0.5s ease forwards; /* Apply fade-in animation */
}

.user {
  font-size:14px;
  font-weight:400;
  align-self: flex-end; /* Align user messages to the left */
  margin-left: auto; /* Remove margin to the left */
  background-color: lightgrey;
  text-align:left;
  color:black;
}

.bot {
  align-self: flex-start; /* Keep bot messages aligned to the left */
  background-color: #f0f0f0;
  color: #333;
  text-align: left;
  margin-right: auto; /* Remove margin to the left */
}
.chat-input-initial {
  border-radius: 24px;
}
.chat-input-initial input {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 24px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.chat-input input {
  flex-grow: 1;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 24px;
  font-size: 14px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.chat-input input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-input button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.chat-input button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

.chat-input button svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 600px) {
  .chat-input {
    padding: 8px 12px;
  }
  
  .chat-input input {
    font-size: 16px; /* Larger font size for mobile */
  }
}

.outfit-results {
  width: 100%;
}

.outfit-results * {
    font-size:14px;
  font-weight:400;
}
.outfit {
  margin-bottom: 20px;
}

.outfit h3 {
  font-size: 16px;
  margin-bottom: 10px;
}


.outfit-item-container, .alternative-item {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.3s ease;
}

.outfit-item-container:hover, .alternative-item:hover {
  transform: translateY(-3px); /* Reduced hover effect */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.outfit-item, .alternative-item > a {
  display: flex;
  padding:5px;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.outfit-item img, .alternative-item img {
  width: 100%;
  height: 70%; /* Increased image height */
  object-fit: contain; /* Changed to cover for better image display */
  transition: transform 0.3s ease;
}

.outfit-item:hover img, .alternative-item:hover img {
  transform: scale(1.05);
}

.item-details {
  padding: 8px; /* Reduced padding */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-name {
  font-size: 12px; /* Reduced font size */
  font-weight: 600;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure only one row */
  line-height: 1.2;
}

.item-price {
  font-size: 14px; /* Reduced font size */
  color: var(--primary-color);
  font-weight: 700;
  margin-top: 2px;
}

.add-to-cart-button, .add-to-cart-button2 {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px; /* Reduced size */
  height: 30px; /* Reduced size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  opacity: 0;
}

.outfit-item-container:hover .add-to-cart-button,
.alternative-item:hover .add-to-cart-button2 {
  opacity: 1;
}

.add-to-cart-button:hover, .add-to-cart-button2:hover {
  transform: scale(1.1);
}

.alternative-carousel {
  margin-top: 16px; /* Reduced margin */
}

.alternative-carousel h4 {
  font-size: 16px;
  margin-bottom: 12px;
  color: var(--primary-color);
}

.carousel {
  display: flex;
  overflow-x: auto;
  padding: 8px 0; /* Reduced padding */
  gap: 12px; /* Reduced gap */
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) #f0f0f0;
}

.carousel::-webkit-scrollbar {
  height: 6px;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 3px;
}

.carousel::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.carousel .alternative-item {
  flex: 0 0 140px; /* Adjusted for more compact layout */
  height: 210px; /* Adjusted height */
}

.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: #d32f2f;
}

.clear-button svg {
  margin-right: 8px;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.initial-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  height: 100%;
  width: 100%;
  gap: 20px;
}


.initial-screen .logo {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.initial-screen h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.initial-screen p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.suggested-queries {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.suggested-queries button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
  color:black;
  cursor: pointer;
  text-align: center;
}

.terms {
  font-size: 12px;
  color: #666;
  max-width: 80%;
}

.terms a {
  color: #007bff;
  text-decoration: none;
}
.start-again {
  margin-right:10px;

}

.start-again, .close-button {
  background-color: var(--primary-color, #000);
  color: #fff;
  border: none;
  border-radius: 1.5625rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  height: 40px; /* Set a fixed height */
}

.start-again:hover, .close-button:hover {
  background-color: var(--secondary-color, #333);
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-dots {
  font-size: 14px;
  color: #666;
}

.dot {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.preview-mode {
  position: static;
  background-color: transparent;
}

.preview-mode .drawer-overlay {
  position: static;
  background-color: transparent;
}

.preview-mode .drawer {
  position: static;
  width: 100%;
  border-radius: var(--border-radius, 20px);
  overflow: hidden;

}

.widget-preview {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: var(--border-radius, 20px);
}

.preview-mode .messages {
  max-height: calc(100% - 150px); /* Adjust based on your header and input heights */
  overflow-y: auto;
}

.preview-mode .chat-input {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;

}

.login-button {
  align-self: flex-end;
  background: none;
  border: none;
  color:black;
  font-size: 18px;
  cursor: pointer;
}


.chat-header-init {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

/* User Profile Styles */
.user-profile {
  display: flex;
  align-items: center; /* Center items vertically */
  margin-bottom: 15px; /* Space below the profile */
}

.profile-image {
  width: 50px; /* Set width for the profile image */
  height: 50px; /* Set height for the profile image */
  border-radius: 50%; /* Make it circular */
  margin-right: 10px; /* Space between image and buttons */
}

.user-options {
  display: flex; /* Align buttons horizontally */
  flex-direction: row; /* Stack buttons vertically */
}

.quiz-button, .logout-button {
  background-color: var(--primary-color, #007bff); /* Primary color */
  color: #fff; /* White text */
  border: none;
  border-radius: 5px; /* Rounded corners */
  padding: 8px 12px; /* Padding for buttons */
  font-size: 14px; /* Font size */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition */
  margin-top: 5px; /* Space between buttons */
}

.quiz-button:hover, .logout-button:hover {
  background-color: var(--secondary-color, #0056b3); /* Darker on hover */
}

/* Adjustments for the chat header */
.chat-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.chat-header h2 {
  flex-grow: 1;
  margin: 0;
  font-size: 18px;
}

.thinking-loader {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
}

.thinking-text {
  margin-right: 8px;
}

.dot-loader {
  display: inline-flex;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #666;
  border-radius: 50%;
  margin: 0 2px;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 60%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(0.4);
    opacity: 0.5;
  }
}

.outfit-item-container {
  position: relative;
}

.explanation-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: help;
  z-index: 1000;
}
